@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Syne:wght@400..800&display=swap");

* {
  font-family: "Syne";
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.font-syne {
  font-family: "Syne";
}
.hero {
  background: url("./Assets/Images/hero_bg.png") no-repeat;
  background-size: cover;
}
.cta {
  background: url("./Assets//Images/cta_bg.png") no-repeat;
  background-size: cover;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #93b311;

  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d3ff25;
}

.first {
  width: inherit;
  max-height: 500px;
  overflow-y: auto;
}
.first::-webkit-scrollbar {
  width: 0px;
}

.gifdiv {
  animation: guy_animate 3s ease infinite 1s;
}
@keyframes guy_animate {
  0% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-15px);
  }
}
.uniqueone {
  /* color: black; */
  -webkit-text-fill-color: black;
  /* Will override color (regardless of order) */
  -webkit-text-stroke: 3px #727272e0;
}
.rotateimage {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.link {
  /* text-gray-300 font-bold text-[20px] */
  font-weight: bold;
  color: rgba(128, 128, 128, 0.663);
  font-size: 20px;
}

.active {
  color: rgb(149, 184, 9);
  font-size: 20px;
  font-weight: bold;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

body {
  background: #262626;
}

.swiper {
  min-height: 300px;

}